import Loader from 'react-loaders';
import './index.scss';
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useModal, Modal } from 'react-morphing-modal';
import 'react-morphing-modal/dist/ReactMorphingModal.css';
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';
import ScrollAnimation from '../../components/Animation/ScrollAnimation';
import AnimatedLetters from '../../components/AnimatedLetters';
import { useEffect, useState } from 'react';


class Work extends Component {

    constructor(props) {
        super(props);
        this.state = {
            xoffset: 0,
            yoffset: 0,
            delta: 10,
        }

    };


    // useEffect(()=> {
    //     setTimeout(() => {
    //         setLetterClass('text-animate-hover')
    //     }, 3000)
    // }, [])

    //const { modalProps, getTriggerProps } = useModal();

    // state = {
    //     xoffset: 0,
    //     yoffset: 0,
    //     delta: 10,
    // };

    handleClickDown = () => {
      if (window.innerWidth > 960) {
        console.log('down');
        if (this.state.yoffset < -50) {
            this.setState({
                //xoffset: this.state.xoffset + 280,
                yoffset: this.state.yoffset + 400,
              })
        }

          console.log(this.state.xoffset);
      };
    }

      handleClickUp = () => {
        console.log('up');
        if (window.innerWidth > 960) {
          if (this.state.yoffset > -7200) {
          this.setState({
              //xoffset: this.state.xoffset - 280,
              yoffset: this.state.yoffset - 400,
            })
          }
            console.log(this.state.xoffset);
        };
      }

      render() {

        
    return (
        <>
        <div>
        <ReactScrollWheelHandler
            upHandler={(e) => this.handleClickDown()}
            downHandler={(e) => this.handleClickUp()}
            >
            <div className="container work-page">
            <div className="text-zone">

                {/* <div>
                    <button {...getTriggerProps()}>Show modal</button>
                    <Modal {...modalProps}><h1>Hello World</h1></Modal>
                </div> */}

                <h1>
                  <HeaderWithLetters />
                </h1>
                <p>
                On the right-hand side you'll find a series of projects I was involved in over the past couple of years. You'll find Projects with various shapes and sizes. Among which there are Symfony sites, WP sites with custom themes, multisite projects, SPAs.
                </p>
                <p>For a more comprehensive list, get in touch...</p>
                <Link to="/contact" className="flat-button">Get in touch</Link>
                <div className="scroll">
                  <div class="scroll__text">SCROLL DOWN</div>
                  <ScrollAnimation />
                </div>
            </div>
            <div className="portfolio" style={{
                    position: "absolute",
                    left: `${this.state.xoffset}px`,
                    top: `${this.state.yoffset}px`,
                }}>
                <div className="portfolio__grid">
                    {/* <ModalHandler /> */}
                    <div className="portfolio__item taqaddam"><a href="https://taqaddam.britishcouncil.org" target="_blank"></a></div>
                    <div className="portfolio__item bloomberg"></div>
                    <div className="portfolio__item nba"><a href="https://classroom.nba.com" target="_blank"></a></div>
                    <div className="portfolio__item wll"><a href="https://worldslargestlesson.globalgoals.org" target="_blank"></a></div>
                    <div className="portfolio__item aia"><a href="https://ahs.aia.com/hk/en" target="_blank"></a></div>
                    <div className="portfolio__item dollar-detectives"><a href="https://cashella.everfi.com" target="_blank"></a><p class="text-block">unlock code: cash3lla</p></div>
                    <div className="portfolio__item premier-league"><a href="https://plprimarystars.com" target="_blank"></a></div>
                    <div className="portfolio__item hsbc"><a href="https://movingtoamericamadeeasy.com" target="_blank"></a></div>
                    <div className="portfolio__item university-of-hull"><a href="https://getsethull.com" target="_blank"></a></div>
                    <div className="portfolio__item get-set"><a href="https://www.getset.co.uk" target="_blank"></a></div>
                    <div className="portfolio__item everfius"><a href="https://everfi.com" target="_blank"></a></div>
                    <div className="portfolio__item nibble"><a href="https://nibblesimply.com" target="_blank"></a></div>
                    <div className="portfolio__item truist"><a href="https://www.truistwordforce.com" target="_blank"></a></div>
                    <div className="portfolio__item dpworld"><a href="https://education.dpworld.com" target="_blank"></a></div>
                    <div className="portfolio__item pl-dashboard"><a href="https://plprimarystars.com/match-stats" target="_blank"></a></div>
                    <div className="portfolio__item massmutual"><a href="https://futuresmart.massmutual.com" target="_blank"></a></div>
                    <div className="portfolio__item principal-foundation"><a href="https://principal.everfi.com" target="_blank"></a></div>
                    <div className="portfolio__item r-generation"><a href="https://r-generation.co.uk" target="_blank"></a></div>
                    <div className="portfolio__item getseteatfresh"><a href="https://getseteatfresh.co.uk" target="_blank"></a></div>
                    <div className="portfolio__item everfi-teachers"><a href="https://everfiteachers.co.uk" target="_blank"></a></div>
                    <div className="portfolio__item urbanplan"><a href="https://urbanplanapp.com" target="_blank"></a></div>
                    <div className="portfolio__item playonuk"><a href="https://playonuk.com" target="_blank"></a></div>
                    <div className="portfolio__item british-rowing"><a href="https://schools.britishrowing.org" target="_blank"></a></div>
                    <div className="portfolio__item boots"><a href="https://soltansunready.com" target="_blank"></a></div>
                    <div className="portfolio__item everfiuk"><a href="https://uk.everfi.com" target="_blank"></a></div>
                    <div className="portfolio__item tamreen"><a href="https://tamreen.qa" target="_blank"></a></div>
                    <div className="portfolio__item pod"><a href="https://jointhepod.org" target="_blank"></a></div>
                    <div className="portfolio__item bpes"><a href="https://bpes.bp.com" target="_blank"></a></div>
                    <div className="portfolio__item item_01"><a href="https://www.newstatesman.com" target="_blank"></a></div>
                    <div className="portfolio__item item_02"><a href="https://elitetraveler.com" target="_blank"></a></div>
                    <div className="portfolio__item item_03"><a href="https://verdict.co.uk" target="_blank"></a></div>
                    <div className="portfolio__item item_04"><a href="https://www.thesamlinghotel.co.uk" target="_blank"></a></div>
                    <div className="portfolio__item item_05"><a href="https://pressgazette.co.uk" target="_blank"></a></div>
                    <div className="portfolio__item item_06"><a href="https://elitetraveler.com/top100" target="_blank"></a></div>
                    <div className="portfolio__item item_07"><a href="https://tech.newstatesman.com" target="_blank"></a></div>
                    <div className="portfolio__item item_08"><a href="https://spearswms.com" target="_blank"></a></div>
                    <div className="portfolio__item item_09"><a href="https://newstatesmanmedia.com" target="_blank"></a></div>
                    <div className="portfolio__item item_10"><a href="https://globaldata.com" target="_blank"></a></div>
                    <div className="portfolio__item item_11"><a href="https://cardsinternational.com" target="_blank"></a></div>
                    <div className="portfolio__item item_12"><a href="https://leasinglife.com" target="_blank"></a></div>
                    <div className="portfolio__item item_13"><a href="https://cbronline.com" target="_blank"></a></div>
                    <div className="portfolio__item item_14"><a href="https://compelo.com" target="_blank"></a></div>
                    <div className="portfolio__item item_16"><a href="https://awards.pressgazette.co.uk" target="_blank"></a></div>
                    <div className="portfolio__item item_17"><a href="https://citymetric.com" target="_blank"></a></div>
                    <div className="portfolio__item item_18"></div>
                    <div className="portfolio__item item_19"><a href="https://wma.spearswms.com" target="_blank"></a></div>
                    <div className="portfolio__item item_20"><a href="https://packaging-gateway.com" target="_blank"></a></div>
                    <div className="portfolio__item item_21"><a href="https://500.spearswms.com" target="_blank"></a></div>
                    <div className="portfolio__item item_22"><a href="https://may2015.com" target="_blank"></a></div>
                    <div className="portfolio__item item_23"><a href="https://subscribe.newstatesman.com" target="_blank"></a></div>
                    <div className="portfolio__item item_24"></div>
                    <div className="portfolio__item item_25"></div>
                    <div className="portfolio__item item_26"><a href="http://www.macronissos.com" target="_blank"></a></div>
                    <div className="portfolio__item item_27"><a href="https://www.buythatmag.com" target="_blank"></a></div>
                    <div className="portfolio__item item_28"><a href="https://www.dansonfoundation.com" target="_blank"></a></div>
                </div>
            </div>
            </div>
            </ReactScrollWheelHandler>
        </div>
        <Loader type="line-scale" />
        </>
    )
    }

}

export default Work;

function HeaderWithLetters() {


  const [letterClass, setLetterClass] = useState('text-animate');
  const titleArray = ['W','o','r','k'];


  useEffect(()=> {
    setTimeout(() => {
        setLetterClass('text-animate-hover')
    }, 3000)
}, [])

  return (
    <AnimatedLetters letterClass={letterClass} strArray={titleArray} idx={10} />
  );

}

function ModalHandler() {
    const { close, modalProps, activeModal, getTriggerProps } = useModal();

    let componentToRender;
  
    switch (activeModal) {
      case 'modal1':
        componentToRender = <ModalContent1 />;
        break;
      case 'modal2':
        componentToRender = <ModalContent2 />;
        break;
      default:
        componentToRender = '';
        break;
    }
  
    return (
      <div>
        <div className="btn-group">
          <button
            {...getTriggerProps({ id: 'modal1' })} className="btn btn__register">
            📜Register
          </button>
          <button {...getTriggerProps('modal2')} className="btn btn__projects">
            🐙 Projects
          </button>
        </div>
        <Modal {...modalProps}>{componentToRender}</Modal>
      </div>
    );
  }

  function ModalContent1() {
    return (
        <section>
            <h2>Modal 1</h2>
            <p>Lorem Ipsum Dolor Sit Amet</p>
        </section>
    );
  }

  function ModalContent2() {
    return (
        <section>
            <h2>Modal 2</h2>
            <p>Lorem Ipsum Dolor Sit Amet</p>
        </section>
    );
  }