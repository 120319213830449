import { Link, NavLink } from 'react-router-dom'
import './index.scss'
import LogoZ from '../../assets/images/logo-s.png'
import LogoSubtitle from '../../assets/images/logo_sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSave, faBookmark, faStar, faEye, faAddressCard, faMap, faFileCode } from '@fortawesome/free-regular-svg-icons'
import { faLinkedin, faGithub, faYoutube, faSkype } from '@fortawesome/free-brands-svg-icons'

const Sidebar = () => (
    <div className='nav-bar'>
        <nav>
            <NavLink end to="/" activeclassname="active">
                <FontAwesomeIcon icon={faBookmark} color="#4d4d4e" />
            </NavLink>
            <NavLink end to="/about" activeclassname="active" className="about-link">
                <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
            </NavLink>
            <NavLink end to="/skills" activeclassname="active" className="skills-link">
                <FontAwesomeIcon icon={faStar} color="#4d4d4e" />
            </NavLink>
            <NavLink end to="/work" activeclassname="active" className="work-link">
                <FontAwesomeIcon icon={faEye} color="#4d4d4e" />
            </NavLink>
            {/* <NavLink exact="true" activeclassname="active" className="blog-link" to="/blog">
                <FontAwesomeIcon icon={faFileCode} color="#4d4d4e" />
            </NavLink> */}
            {/* <NavLink exact="true" activeclassname="active" className="contact-link" to="/contact">
                <FontAwesomeIcon icon={faMap} color="#4d4d4e" />
            </NavLink> */}
        </nav>
        <ul>
            <li><a target="_blank" rel='noreferrer' href="https://www.linkedin.com/in/zoltan-hack-3143b568/"><FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" /></a></li>
            {/* <li><a target="_blank" rel='noreferrer' href="/"><FontAwesomeIcon icon={faGithub} color="#4d4d4e" /></a></li> */}
            {/* <li><a target="_blank" rel='noreferrer' href="/"><FontAwesomeIcon icon={faYoutube} color="#4d4d4e" /></a></li> */}
            <li><a target="_blank" rel='noreferrer' href="https://join.skype.com/invite/iZJqitY8SMJF"><FontAwesomeIcon icon={faSkype} color="#4d4d4e" /></a></li>
        </ul>
        <a id="mobile-link" href=""><i class="fa fa-bars"></i></a>
    </div>
)

export default Sidebar