
import { Outlet } from 'react-router-dom';
import Sidebar from '../Sidebar'
import './index.scss';

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import locomotiveScroll from "locomotive-scroll";

import $ from 'jquery';

import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";


const Layout = () => {


    // const scrollRef = React.createRef();

    // useEffect(() => {
    //   const scroll = new locomotiveScroll({
    //     el: scrollRef.current,
    //     smooth: true
    //   });
    // });


    return (
    <div className="App">
        <Sidebar />
        {/* <div className="scroll" ref={scrollRef}>
            <h1 data-scroll data-scroll-speed="3" data-scroll-position="top">
            Locomotive Scroll in React
            </h1>
            <h2
            data-scroll
            data-scroll-speed="2"
            data-scroll-position="top"
            data-scroll-direction="horizontal"
            >
            Ima go sideways
            </h2>
        </div> */}
        <div className="page">
            <Outlet />
        </div>
    </div>
    )
}

export default Layout