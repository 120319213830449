import Loader from 'react-loaders';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import './index.scss';
import { useEffect, useState } from 'react'
import emailjs from '@emailjs/browser'
import { useRef } from 'react'
import AnimatedLetters from '../../components/AnimatedLetters';

const Contact = () => {
    const form = useRef()
  
    // useEffect(() => {
    //   return setTimeout(() => {
    //     setLetterClass('text-animate-hover')
    //   }, 3000)
    // }, [])
  
    const [letterClass, setLetterClass] = useState('text-animate')
    const titleArray = ['C','o','n','t','a','c','t',' ','m','e']

    useEffect(()=> {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    const sendEmail = (e) => {
      e.preventDefault()
  
      emailjs
        .sendForm('gmail', 'template_g140p9i', form.current, 'your-token')
        .then(
          () => {
            alert('Message successfully sent!')
            window.location.reload(false)
          },
          () => {
            alert('Failed to send the message, please try again')
          }
        )
    }
  
    return (
      <>
        <div id="contact" className="container contact-page">
          <div className="text-zone">
            <h1>
              <AnimatedLetters
                letterClass={letterClass}
                strArray={titleArray}
                idx={15}
              />
            </h1>
            <p>
              For more details get in touch...
            </p>
            <div className="contact-form">
              <form ref={form} onSubmit={sendEmail}>
                <ul>
                  <li className="half">
                    <input placeholder="Name" type="text" name="name" required />
                  </li>
                  <li className="half">
                    <input
                      placeholder="Email"
                      type="email"
                      name="email"
                      required
                    />
                  </li>
                  <li>
                    <input
                      placeholder="Subject"
                      type="text"
                      name="subject"
                      required
                    />
                  </li>
                  <li>
                    <textarea
                      placeholder="Message"
                      name="message"
                      required
                    ></textarea>
                  </li>
                  <li>
                    <input type="submit" className="flat-button" value="SEND" />
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
        <Loader type="line-scale" />
      </>
    )
  }
  
  export default Contact