import Loader from 'react-loaders';
import './index.scss';
import { useEffect, useState } from 'react';
import AnimatedLetters from '../../components/AnimatedLetters';
import { Link } from 'react-router-dom';
import CV from '../../assets/Zoltan_Hack_CV_2023.pdf';

const About = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const titleArray = ['A','b','o','u','t',' ','m','e']

    useEffect(()=> {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    return (
        <>
        <div className="container about-page">
            <div className="text-zone">
                <h1>
                    <AnimatedLetters letterClass={letterClass} strArray={titleArray} idx={10} />
                </h1>
                <div class="paragraph">
                    <p>I have been working on websites and web applications for the past 10 years
and though my focus has always been Front End Development, there has
hardly been any job where I didn’t have to get involved in the Back End side of
things, one way or another. Whether it was tracing back bugs, resolving issues in the
Controller, participating in data migration or just simply configure infrastructure using
Varnish Cache.</p>
                    <p>
                    In one of my previous positions based in Central London, as Head of Development, I was responsible for leading digital teams in a fast paced environment. I have always had a good eye to oversee different phases of a project and build products from start to finish. For the past couple of years I have been working as a contractor.
                    </p>
                    <p>In my current position I have been fortunate enough to work alongside talented
professionals with various clients such as Premier League, Qatar 2022, BBC, NBA, Public Health England, British Council, Unicef, BP or EDF Energy.
                    </p>
                </div>
                <a href={CV} target="_blank" className="flat-button">Click here to download my CV</a>
            </div>
        </div>
        <Loader type="line-scale" />
        </>
    )

}

export default About