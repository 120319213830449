import Loader from 'react-loaders';
import './index.scss';
import { useEffect, useState } from 'react';
import AnimatedLetters from '../../components/AnimatedLetters';

const Blog = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const titleArray = ['B','l','o','g']

    useEffect(()=> {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    return (
        <>
        <div className="container contact-page">
            <div className="text-zone">
                <h1>
                    <AnimatedLetters letterClass={letterClass} strArray={titleArray} idx={10} />
                </h1>
                <div class="paragraph">
                <p>
                Curabitur pharetra tellus ipsum. Curabitur nunc lorem, mattis in gravida a, posuere a turpis. Donec commodo vestibulum enim in auctor. Nam ullamcorper faucibus felis, at scelerisque risus fermentum sed. Proin lectus nunc, porttitor sit amet tortor nec, cursus lobortis tortor. Etiam sed tellus tincidunt, dignissim enim eget, accumsan ligula. Maecenas interdum blandit ullamcorper.
                </p>
                <p>Vestibulum sed vulputate lectus. Vestibulum leo sem, suscipit non consequat ac, eleifend eget dolor. Nulla accumsan vitae ante a porta. Vestibulum pretium, ligula quis interdum lobortis, nulla turpis convallis diam, volutpat efficitur turpis tortor eu nisi. Praesent nisi ligula, commodo eu leo et, dictum commodo augue. Pellentesque nec nisl sodales odio ornare gravida. Aliquam pellentesque ac tortor a cursus.
                </p>
                <p>
                Duis faucibus orci ut erat varius, ac volutpat lectus maximus. Fusce rhoncus arcu id ligula efficitur, eget dignissim risus tempor. Aenean in diam rutrum, eleifend magna id, consequat sapien. Sed malesuada ipsum nibh, quis eleifend lacus maximus non. Vivamus ullamcorper fringilla ex, at facilisis libero eleifend nec. Nam hendrerit sollicitudin tellus, vel lacinia arcu mattis vehicula. Morbi lacinia nunc a libero posuere maximus. Etiam dictum, arcu non rhoncus facilisis, tellus velit congue felis, blandit hendrerit eros dui vitae erat. Morbi velit mauris, iaculis a elementum sed, semper vel risus. Nulla in mollis leo.
                </p>
                <h2>React starter projects</h2>
                <p>
                    <ul>
                        <li>Cashella</li>
                        <li>Space photo of the day</li>
                        <li>Viewability Dashboard (a mini Saas project of mine)</li>
                        <li>Ron Swanson quote of the day</li>
                        <li>Interactive Tube map</li>
                    </ul>
                </p>
                <h2>Fancy codepen bits</h2>
                <p>
                    <ul>
                        <li>One</li>
                        <li>Two</li>
                        <li>Three</li>
                    </ul>
                </p>
                <h2>Sorting algorithms written in React</h2>
                <p>
                    <ul>
                        <li>One</li>
                        <li>Two</li>
                        <li>Three</li>
                    </ul>
                </p>
                </div>
            </div>
        </div>
        <Loader type="line-scale" />
        </>
    )

}

export default Blog