import Lottie from "lottie-react";
import scrollAnimation1 from "../../assets/images/scrollAnimation1.json";
import scrollAnimation2 from "../../assets/images/scrollAnimation2.json";

const ScrollAnimation = () => {
    return <div class="scroll__animation"><Lottie loop animationData={scrollAnimation1} /><Lottie loop animationData={scrollAnimation2} /></div>;
  };
  
export default ScrollAnimation;

