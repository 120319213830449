import HomeLogo from '../../assets/images/Z.jpg';
import { Link, NavLink } from 'react-router-dom';
import Loader from 'react-loaders';
import './index.scss';
import { useEffect, useState } from 'react';
import AnimatedLetters from '../../components/AnimatedLetters';
import HomeAnimation from '../../components/Animation/HomeAnimation';




const Home = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['H','e','l','l','o',',']
    const nameArray2 = ['I','\'','m','  ','Z','o','l','t','a','n',' ','H','a','c','k']
    const jobArray = ['f','r','o','n','t',' ','e','n','d',' ','d','e','v','e','l','o','p','e','r']

    useEffect(()=> {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    return (
        <>
        <div id="home" className="container home-page">
            <div className="text-zone">
                {/* <h1>Hi, <br />I'm a front <br />end developer
                </h1> */}
                <h1>
                <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={1} />
                <br />
                <AnimatedLetters letterClass={letterClass} strArray={nameArray2} idx={7} />
                <br />
                <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={11} />
                {/* <h1>Hi, <br />I'm a trilingual, <br />
                British-Hungarian<br />
                front end developer <br />
                living in Poland
                </h1> */}
                </h1>
                {/* <h2>What's <u>your</u> superpower?</h2> */}
                <Link to="/about" className="flat-button">Find out more...</Link>
            </div>
            <HomeAnimation />
            <svg viewBox="0 0 200 200">
                <defs>
                    <linearGradient id="gradient" gradientTransform="rotate(90)">
                    <stop id="gradientStop1" offset="0%" stop-color="var(--startColor)" />
                    <stop id="gradientStop2 " offset="100%" stop-color="var(--stopColor)" />
                    </linearGradient>
                </defs>
                <path d="" fill="url('#gradient')"></path>
            </svg>
            {/* <img class="photo" src={HomeLogo} /> */}
        </div>
        <Loader type="line-scale" />
        </>
    );
}

export default Home