import './App.scss'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Layout from './components/Layout'

import Home from './views/Home'
import About from './views/About'
import Skills from './views/Skills'

// To do still
import Contact from './views/Contact'
import Work from './views/Work'
import Blog from './views/Blog'

function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/work" element={<Work />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
      </Route>
    </Routes>
    </>
  );
}

export default App;
