import Loader from 'react-loaders';
import './index.scss';
import React, { Component } from 'react';
import { ParallaxProvider, useParallax, Parallax } from 'react-scroll-parallax';
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';

import LogoReact from '../../assets/images/logo_react.png';
import LogoJS from '../../assets/images/logo_js.png';
import LogoWP from '../../assets/images/logo_wp.png';
import LogoSass from '../../assets/images/logo_sass.png';
import LogoSymfony from '../../assets/images/logo_symfony.png';

import AnimatedLetters from '../../components/AnimatedLetters';
import ScrollAnimation from '../../components/Animation/ScrollAnimation';
import { useEffect, useState } from 'react';

class Skills extends Component {


    constructor(props) {
        super(props);
        this.state = {
            xoffset: 0,
            yoffset: 0,
            delta: 10,
        }
    };

    handleClickDown = () => {
        if (window.innerWidth > 960) {
            console.log('down');
            if (this.state.yoffset < -50) {
                this.setState({
                    //xoffset: this.state.xoffset + 280,
                    yoffset: this.state.yoffset + 200,
                })
            }

            console.log(this.state.xoffset);
        }
      };

      handleClickUp = () => {
        console.log('up');
        if (window.innerWidth > 960) {
            if (this.state.yoffset > -7200) {
            this.setState({
                //xoffset: this.state.xoffset - 280,
                yoffset: this.state.yoffset - 200,
            })
            }
            console.log(this.state.xoffset);
        }
      };

      render() {
    return (
        <>
        <div>
        <ReactScrollWheelHandler
            upHandler={(e) => this.handleClickDown()}
            downHandler={(e) => this.handleClickUp()}
            >
            <div className="container skills-page">
            <div className="text-zone">
                <h1>
                  <HeaderWithLetters />
                </h1>
                <div class="logos">
                    <img class="logo logo--js" src={LogoJS} />
                    <img class="logo logo--sass" src={LogoSass} />
                    <img class="logo logo--wp" src={LogoWP} />
                    <img class="logo logo--react" src={LogoReact} />
                    <img class="logo logo--symfony" src={LogoSymfony} />
                </div>
                {/* <p>Fancy keywords you'll be interested in:</p> */}
                <div className="scroll">
                  <div class="scroll__text">SCROLL DOWN</div>
                  <ScrollAnimation />
                </div>
            </div>
            <div className="skills" style={{
                    position: "absolute",
                    left: `${this.state.xoffset}px`,
                    top: `${this.state.yoffset}px`,
                }}>
                <h3>FE exposure</h3>
                <ul>
                    <li>JavaScript ES5, ES6 / TypeScript</li>
                    <li>HTML5</li>
                    <li>CSS3 / SCSS / SASS / TailwindCSS / Susy</li>
                    <li>Bootstrap / Foundation / Material UI</li>
                    <li>React / React Native / jQuery</li>
                    <li>Next.js / Gatsby / Astro</li>
                </ul>
                <h3>BE exposure</h3>
                <ul>
                    <li>MySQL / Postgres / MongoDB / NodeJS</li>
                    <li>GraphQL with static sites</li>
                </ul>
                <h3>Dev workflow / tools</h3>
                <ul>
                    <li>Bundling / Workflow - Grunt, Gulp, Yarn, Webpack</li>
                    <li>Docker / Lando / MAMP / XAMPP / WAMP</li>
                    <li>Postgres / PgAdmin</li>
                </ul>
                <h3>Design Tools</h3>
                <ul>
                    <li>Figma / Sketch / InVision</li>
                    <li>Adobe XD / Illustrator / Photoshop</li>
                </ul>
                <h3>Template Engines</h3>
                <ul>
                    <li>Twig / Mustache / Handlebars</li>
                </ul>
                <h3>CMS</h3>
                <ul>
                    <li>WordPress - extensive - 30+ projects</li>
                    <li>Custom CMS on Symfony - extensive - 10+ projects</li>
                    <li>Shopify</li>
                    <li>Umbraco</li>
                    <li>Bolt CMS</li>
                    <li>Drupal</li>
                </ul>
                <h3>Hosting / Infrastructure</h3>
                <ul>
                    <li>AWS Amplify / Azure / WP Engine / Pantheon / SiteGround / HostGator / Acquia</li>
                    <li>Rackspace / Bamboo</li>
                </ul>
                <h3>Version Control</h3>
                <ul>
                    <li>GIT / SVN</li>
                </ul>
                <h3>Third party integrations</h3>
                <ul>
                    <li>DFP / Google Ads</li>
                    <li>Salesforce</li>
                    <li>GTM</li>
                    <li>Zapier</li>
                    <li>Stripe</li>
                    <li>CampaignMonitor</li>
                    <li>Klaviyo</li>
                    <li>Mailchimp</li>
                </ul>
                <h3>Daily dev life</h3>
                <ul>
                    <li>Tickets and task management: JIRA / Trello / Asana / Assembla</li>
                    <li>Communications: Slack / Microsoft Teams / Hangouts</li>
                    <li>Performance optimisation and monitoring: New Relic / Pingdom</li>
                </ul>
                <h3>API work</h3>
                <ul>
                    <li>Google Maps API</li>
                    <li>Google Sheets API</li>
                    <li>DPD API (ad agencies) - inView actions</li>
                    <li>JWT access tokens</li>
                    <li>Session based authentication / Token based authentication</li>
                </ul>
            </div>
        </div>
                </ReactScrollWheelHandler>
        </div>

        <Loader type="line-scale" />
        </>
    )
    }

}

export default Skills;


function HeaderWithLetters() {


    const [letterClass, setLetterClass] = useState('text-animate');
    const titleArray = ['S','k','i','l','l','s'];
  
  
    useEffect(()=> {
      setTimeout(() => {
          setLetterClass('text-animate-hover')
      }, 3000)
  }, [])
  
    return (
      <AnimatedLetters letterClass={letterClass} strArray={titleArray} idx={10} />
    );
  
  }